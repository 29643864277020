/* .MuiDataGrid-root.MuiDataGrid-root {
  font-size: 1.5rem;
}
tr:hover {
  background-color: #d6d6d6;
} */
.MuiDataGrid-row:hover {
  cursor: pointer !important;
}

.pointer {
  cursor: pointer;
}

.otp__sections input[type="text"] {
  width: 3rem !important;
  height: 4rem !important;
  margin: 20px !important;
  text-align: center !important;
}



.speaker_model_con {
  z-index: 2 !important;
}

.hng {
  z-index: 1 !important;
}