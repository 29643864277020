* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.srv-validation-message,
.text-danger {
  color: red;
  text-align: center;
  font-size: small;
  margin-top: -2rem;
  padding-left: 13rem;
}

/*=============== custom styles ==================*/
button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-button {
  background-color: #e5e5e5;
}

::-webkit-scrollbar-corner {
  background-color: #555;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f5f5f5;
}

.txt-cap,
.table-responsive {
  text-transform: capitalize;
}

/*carousel*/
.carou-card .react-multiple-carousel__arrow {
  top: 50px;
  min-width: 30px;
  min-height: 30px;
  background: rgba(0, 0, 0, 0.3);
}

.carou-card .react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.5);
}

.carou-card .react-multiple-carousel__arrow:focus {
  outline: none;
}

.carou-card .react-multiple-carousel__arrow::before {
  font-size: 14px;
}

.carou-card .react-multiple-carousel__arrow--right {
  right: 0;
}

.carou-card .react-multiple-carousel__arrow--left {
  left: 0;
}

.carou-slider {
  margin: 0 10px;
}

/*form fields*/
/* .MuiFormControl-root {
  font-size: 1rem;
} */
/* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0 !important;
  font-size: 100%;
} */

.MuiTextField-root,
.MuiFormControl-root {
  width: 100%;
  margin: 0 !important;
  font-size: 100%;
}

.MuiGridFilterForm-closeIcon {
  width: auto !important;
}

.MuiFormControl-root,
.MuiTextField-root,
.MuiInputLabel-root,
.MuiOutlinedInput-root,
.MuiDataGrid-root,
.MuiMenuItem-root,
.MuiPickersDay-root,
.MuiTypography-root,
.css-l0iinn,
.MuiTab-root,
.MuiInput-root,
.MuiButton-root,
.MuiTextField-root label {
  font-size: 100% !important;
}

/* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root label,
.css-1869usk-MuiFormControl-root label,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root,
.css-1w13o7u-MuiTypography-root,
.css-l0iinn,
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root,
.MuiDataGrid-root,
.css-2m9kme-MuiFormControl-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-10hburv-MuiTypography-root,
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root,
.css-q8hpuo-MuiFormControl-root,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
.css-ahj2mt-MuiTypography-root,
.css-1480iag-MuiInputBase-root-MuiInput-root,
.css-z2eky3-MuiTypography-root,
.css-a88p61-MuiInputBase-root-MuiInput-root,
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  font-size: 100% !important;
} */
/* .css-i4bv87-MuiSvgIcon-root {
  font-size: 2.5rem !important;
} */
.MuiSvgIcon-root {
  font-size: 2.5rem !important;
}

/* .css-fd2y78-MuiSvgIcon-root,
.css-f4950f-MuiSvgIcon-root,
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
.css-ptiqhd-MuiSvgIcon-root {
  font-size: 190% !important;
} */
/* .css-levciy-MuiTablePagination-displayedRows {
  font-size: 14px !important;
  margin: 0;
} */
.MuiDataGrid-toolbarContainer {
  justify-content: end;
  background: beige;
}

.MuiDataGrid-toolbarContainer button {
  color: #777777;
  font-size: 11px !important;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel,
.MuiTablePagination-input {
  font-size: 14px !important;
  margin: 0;
}

.add-in {
  display: inline-flex;
}

.add-in-btn {
  width: 130px;
  margin-left: 5px;
}

.btn-fill-lg {
  padding: 5px 24px;
}

.form-control {
  font-size: 14px;
}

/*profile*/
.profile-sec {
  display: block;
}

.pro-img img {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.pro-content {
  text-align: center;
  margin: 0;
}

.pro-content h6 {
  color: #eea11d;
  font-weight: 500;
  text-transform: capitalize;
  margin: 13px 0 12px 0;
  font-size: 18px;
}

/* .dashboard-summery-one {
  padding: 15px 15px;
} */
.breadcrumbs-area ul li {
  text-transform: capitalize;
  font-weight: 500;
}

/*course detail*/
.flx {
  display: inline-flex;
}

.upl {
  cursor: pointer;
}

.upl img {
  max-width: 53px;
  margin-left: 8px;
  border-radius: 3px;
  object-fit: contain;
  border: 1px solid #ccc;
  height: 53px;
}

.upl i {
  right: 10px !important;
  top: 41px !important;
  position: absolute;
}

/* .r-m .css-2m9kme-MuiFormControl-root {
  margin: 0;
} */
.r-m .MuiFormControl-root {
  margin: 0;
}

.upload-section {
  border: 1px solid #efa41c;
  border-radius: 4px;
  position: relative;
}

.h-l {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 4px;
  color: #112954;
}

.month-add {
  justify-content: space-between;
}

.month-field-ali {
  margin-left: 15px !important;
}

.add-m-t {
  right: 15px;
}

.add-m-t button {
  float: right;
  width: max-content;
}

.topic-header {
  font-weight: 500;
  background: #f0ad19;
  color: #fff;
  padding: 5px 0;
}

.p-l {
  padding-left: 27px;
}

.upl-icn i {
  line-height: 43px;
  font-size: 2rem;
  cursor: pointer;
}

.action-btns button {
  border: 1px solid #f00;
  border-radius: 3px;
  background: rgb(255 0 0 / 10%);
  padding: 1px 5px;
  margin: 10px 6px 0 0;
  cursor: pointer;
}

.r-btn {
  border: 1px solid #3c76d2 !important;
  background: rgb(60 118 210 / 10%) !important;
}

.topic-content {
  border-bottom: 1px solid #ccc;
}

.upld {
  line-height: 43px;
}

.upld i {
  color: #ff0000;
  font-size: 1.6rem;
  padding-left: 5px;
  top: 2px;
  position: relative;
}

.w-topics .css-19kzrtu {
  padding: 0 15px;
}

/* .w-topics .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #112954;
} */
.w-topics .MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #112954;
}

/* .w-topics .css-1aquho2-MuiTabs-indicator {
  background-color: #112954;
} */
.w-topics .MuiTabs-indicator {
  background-color: #112954;
}

.glipmse {
  text-align: right;
}

/*view course*/
.main-h {
  text-align: center;
  font-weight: 500;
  color: #112954;
}

.w-t-view .css-19kzrtu {
  padding: 15px 0 0 0;
}

.view-txt {
  text-align: justify;
}

.g-sec .item-content {
  text-align: left;
}

.g-img img {
  object-fit: cover;
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

.c-profile {
  box-shadow: 0 0 20px rgb(51 51 51 / 10%);
  border-radius: 10px;
  padding: 0;
}

.c-profile img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: 10px 10px 0 0;
}

.c-content {
  justify-content: space-between;
  display: flex;
  padding: 7px 10px;
  border-bottom: 1px dashed #e0e0e0;
}

.c-values {
  color: #222;
  font-weight: 500;
}

.c-values span {
  display: block;
  text-align: right;
}

.a-slots {
  font-size: 1.5rem;
  color: #ffffff;
  padding: 0 6px;
  border-radius: 4px;
  background: #50a853;
}

.t-a-s {
  border: 1px solid #50a853;
  background: rgb(80, 168, 83, 10%);
  border-radius: 25px;
  margin: 0 12px 12px;
}

.c-price {
  color: #ffffff;
  background: #50a853;
  padding: 0 15px;
  border-radius: 25px;
}

.price-sec {
  display: flex;
  margin-bottom: 3px;
  justify-content: flex-end;
}

.region-name {
  padding-right: 10px;
}

.c-time {
  text-align: right;
  text-transform: capitalize;
}

.c-profile div:last-child {
  border: none;
}

.w-t-view h6 {
  margin-bottom: 0;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}

/* .css-46bh2p-MuiCardContent-root {
  padding: 0 !important;
  background: rgb(239, 167, 27, 5%);
} */
.MuiCardContent-root {
  padding: 0 !important;
  background: rgb(239, 167, 27, 5%);
}

.chap-sec {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.chap-main div:last-child {
  border: 0;
}

.material-icns {
  justify-content: space-between;
  display: flex;
}

.material-icns button {
  border: 0;
  background: none;
}

.material-icns i {
  background: rgb(209, 45, 65, 10%);
  padding: 3px 10px;
  border-radius: 3px;
  color: #d12928;
  border: 1px solid #d12928;
}

.material-lock i {
  color: #959494;
}

.t-profile {
  box-shadow: 0 0 20px rgb(51 51 51 / 10%);
  border-radius: 10px;
  padding: 0;
}

.t-profile img {
  object-fit: cover;
  width: 194px;
  height: 194px;
  border-radius: 50%;
  padding: 10px;
}

.t-slot-sec {
  display: flex;
  justify-content: space-between;
}

.t-slot-sec p {
  line-height: 2;
}

.t-p-txt {
  padding: 10px 25px 10px 10px;
}

.t-p-txt h4 {
  color: #efa71b;
  text-transform: capitalize;
}

.t-p-txt p {
  margin: 0;
}

.t-p-txt p span {
  font-weight: 500;
  color: #222222;
}

.t-p-txt h6 {
  margin: 15px 0 5px 0;
  text-decoration: underline;
}

.t-contact {
  display: flex;
}

.t-contact i {
  background: rgb(80, 168, 83, 15%);
  color: #50a853;
  padding: 6px 0 0 8px;
  border-radius: 26px;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.t-contact p {
  padding-right: 30px;
}

.t-mail {
  padding: 6px 0 0 5px !important;
  background: rgba(255, 0, 0, 15%) !important;
  color: #ff0000 !important;
}

.i-a-teacher {
  display: flex;
}

.i-a-teacher button {
  margin-right: 15px;
}

.c-n-c {
  margin-top: 11px;
}

.g-card {
  width: 100%;
}

.g-card .dashboard-summery-one {
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
}

/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px 6px 20px 0px rgb(0 0 0 / 15%) !important;
} */
.MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px 6px 20px 0px rgb(0 0 0 / 15%) !important;
}

/* .css-15v22id-MuiAccordionDetails-root {
  padding: 0px 16px 8px;
  border-top: 1px solid rgb(228, 227, 227);
} */
.MuiAccordionDetails-root {
  padding: 0px 16px 8px;
  border-top: 1px solid rgb(228, 227, 227);
}

.g-actions {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 4px;
}

.g-actions i {
  background: rgb(17, 41, 84, 10%);
  padding: 4px;
  border-radius: 3px;
  font-size: 11px;
  margin-right: 3px;
  margin-top: 3px;
  color: #112954;
  border: 1px solid #112954;
  cursor: pointer;
}

.del-ac {
  background: rgb(220, 53, 69, 10%) !important;
  color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}

.cur-lab {
  padding: 10px;
  background: aliceblue;
  font-weight: 500;
}

/*auth*/
.sign-sec {
  width: 470px;
  padding: 3rem !important;
  margin-bottom: 0px !important;
}

.auth-logo {
  margin-bottom: 25px !important;
}

.auth-logo img {
  width: 40%;
}

.pre-link {
  color: #112954 !important;
  text-align: center;
}

.pre-link a {
  color: #ff9d01 !important;
  font-weight: 500;
}

.l-btn {
  margin-top: 15px !important;
}

.o-t-p {
  display: flex;
  justify-content: space-between;
}

.o-t-p input {
  width: 97px;
  text-align: center;
}

.r-send {
  text-align: right;
  flex: auto;
  padding-right: 15px;
}

.send-otp {
  flex: auto;
}

.o-t-p-btn {
  text-align: center;
}

.up-img-sec {
  position: relative;
}

.up-img-sec i {
  position: absolute;
  right: 0;
  bottom: -6px;
  font-size: 3rem;
}

.pick-op {
  margin-right: 22px;
}

.f-p-ali {
  text-align: right;
}

.donotshow {
  display: none;
}

.error p {
  color: tomato;
  font-size: 12px;
}

.become-a {
  padding-top: 1.8rem;
}

.become-a h6 {
  font-weight: 600;
  color: #efa31d;
}

.become-a h6 span {
  color: #112954;
}

.pointing-icn {
  padding: 0 22px 0 8px;
  color: #112954 !important;
}

.s-n {
  font-size: 14px;
  font-weight: normal;
}

.become-swit {
  top: -7px;
}

.custom-header-menu {
  padding: 0 20px 0 31px;
}

/*my account*/
.u-l-img {
  display: grid;
  justify-content: center;
}

.u-l-img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.u-l-img i {
  position: relative !important;
  top: -18px !important;
  left: 95px !important;
  font-size: 2rem !important;
}

.all-act-btn {
  padding: 14px 24px;
  margin-right: 15px;
}

.new-btn-sty {
  display: flex;
  justify-content: space-between;
}

.new-btn-sty button {
  width: auto;
  padding: 6px 11px;
  height: max-content;
}

.ev-field {
  justify-content: space-between;
}

.hng .MuiBox-root {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 90%;
}.sun-editor .se-wrapper .se-wrapper-inner{
  overflow: hidden !important;
  height: auto !important;
}
.bhanu table,tr,th,td{
  text-align: center;
  border: 1px solid black;
}
/* .se-toolbar{
  z-index: 4!important;
}
.se-container{
  z-index: 5 
} */